<template>
  <div class="row mx-0 justify-content-center">
    <div>
      <div class="col-sm-12 d-flex d-print-none w-100 mb-4">
        <button class="btn btn-orange w-100" @click="print">Imprimir</button>
      </div>
      <div
        class="invoice d-block px-3 py-4 mb-3"
        v-for="(cardTransaction, index) of payment.cardTransactions"
        :key="index"
      >
        <div class="w-100 text-center mb-4 mt-2">
          <h6
            class="text-uppercase"
            v-if="cardTransaction.status === 'approved'"
          >
            Comprovante de pagamento
          </h6>
          <h6
            class="text-uppercase"
            v-if="cardTransaction.status === 'canceled'"
          >
            Comprovante de cancelamento
          </h6>
        </div>
        <div class="w-100 d-flex mb-3">
          <div class="mr-auto">
            <p class="text-uppercase mb-0">
              {{ cardTransaction.cardBrand }} -
              {{ cardTransaction.credit ? "crédito" : "débito" }}
            </p>
            <p class="mb-0" v-if="cardTransaction.status === 'approved'">
              <strong>Data:</strong>
              {{ cardTransaction.paidAt | moment("DD/MM/YYYY HH:mm:ss") }}
            </p>
            <p class="mb-0" v-if="cardTransaction.status === 'canceled'">
              <strong>Cancel.:</strong
              >{{ cardTransaction.canceledAt | moment("DD/MM/YYYY HH:mm:ss") }}
            </p>
            <p class="mb-0">
              **** **** **** **** {{ cardTransaction.cardFinal }}
            </p>
          </div>
          <div class="text-center ml-4">
            <h5 class="font-weight-bold mb-0 text-nowrap">
              {{
                payment.amount
                  | currency("R$ ", 2, {
                    decimalSeparator: ",",
                    thousandsSeparator: ".",
                  })
              }}
            </h5>
            <p class="text-nowrap mb-0">
              {{ payment.cardTransactions[0].installments }} x
              {{
                payment.cardTransactions[0].installmentValue
                  | currency("R$ ", 2, {
                    decimalSeparator: ",",
                    thousandsSeparator: ".",
                  })
              }}
            </p>
          </div>
        </div>
        <hr v-if="cardTransaction.online" />
        <div
          class="text-center p-2 border border-secondary rounded"
          v-if="!cardTransaction.online"
        >
          <p class="mb-0">Aprovação realizada mediante<br />uso de senha.</p>
        </div>
        <div class="mt-3">
          <h6 class="text-uppercase font-weight-bold mb-0">
            {{ preset.account.comercialName }}
          </h6>
          <p class="text-uppercase mb-0">
            {{ preset.address.city.name }}-{{ preset.address.city.state }}
          </p>
          <p>
            {{ preset.address.district }}, {{ preset.address.line1 }}
            {{ preset.address.streetNumber }}, {{ preset.address.line2 }}
          </p>
          <div class="d-flex">
            <p class="mr-auto mb-0" v-if="preset.holder.company">
              {{ preset.holder.company.taxDocument }}
            </p>
            <p class="mb-0"><strong>NSU: </strong>{{ cardTransaction.nsu }}</p>
          </div>
          <div class="text-center mt-3" v-if="cardTransaction.online">
            <p>Assinatura: __________________</p>
          </div>
          <div class="text-center mt-2 m-0">
            <p>Processado pela Pagcerto</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentsApi from '@/services/PaymentsApi';
import AccountApi from '@/services/AccountApi';
export default {
  name: 'AppInvoice',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.find();
    await this.presets();
    await this.askWhoAmI();
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      payment: '',
      preset: '',
    };
  },
  methods: {
    print(print) {
      window.print();
    },
    async find() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      this.payment = await api.findPayment(this.$route.params.paymentId);
      this.$bus.$emit('spinner-stop');
    },
    async presets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },
  },
};
</script>
